<template>
  <header :class="{ 'fixed-top': isHeaderFixed }" id="header" class="d-flex align-items-center">
    <div class="container d-flex justify-content-between">
      <div class="logo">
        <h1 class="text-light"><a href="/">DanceTillDawn</a></h1>
      </div>

      <nav id="navbar" class="navbar" :class="{ 'navbar-mobile': isMobileNavOpen }">
        <ul>
          <li>
            <a class="nav-link scrollto" :class="{ 'active': activeSection === 'hero' }" href="#hero" @click="handleNavClick">Home</a>
          </li>
          <li>
            <a class="nav-link scrollto" :class="{ 'active': activeSection === 'about' }" href="#about" @click="handleNavClick">About</a>
          </li>
          <li>
            <a class="nav-link scrollto" :class="{ 'active': activeSection === 'services' }" href="#services" @click="handleNavClick">Services</a>
          </li>
          <li>
            <a class="nav-link scrollto" :class="{ 'active': activeSection === 'portfolio' }" href="#portfolio" @click="handleNavClick">Highlights</a>
          </li>
          <li>
            <a class="nav-link scrollto" :class="{ 'active': activeSection === 'portfolio' }" href="#pricing" @click="handleNavClick" >Pricing</a>
          </li>
          <li>
            <a class="nav-link scrollto" :class="{ 'active': activeSection === 'contact' }" href="#contact" @click="handleNavClick">Contact</a>
          </li>
        </ul>
        <i 
          class="bi mobile-nav-toggle" 
          :class="!isMobileNavOpen ? 'bi-list':'bi-x'"
          @click="toggleMobileNav">
        </i>
      </nav><!-- .navbar -->
    </div>
  </header><!-- End Header -->
</template>

<script>
export default {
  data() {
    return {
      isHeaderFixed: false,
      activeSection: null,
      isMobileNavOpen: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    // document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    // document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    handleScroll() {
      const scrollThreshold = 250;
      this.isHeaderFixed = window.scrollY > scrollThreshold;
      const activeSection = this.getSectionByScroll();

      const links = document.querySelectorAll('#navbar ul li a');
      links.forEach(link => link.classList.remove('active'));

      if (activeSection) {
        const activeLink = document.querySelector(`#navbar ul li a[href="#${activeSection.toLowerCase()}"]`);
        if (activeLink) {
          activeLink.classList.add('active');
        }
      }
    },
    getSectionByScroll() {
      const scrollPosition = window.scrollY + window.innerHeight / 2 + 250;
      const sections = [
        { id: 'hero', name: 'Home' },
        { id: 'about', name: 'About' },
        { id: 'cta', name: 'cta' },
        { id: 'services', name: 'Services' },
        { id: 'portfolio', name: 'Highlights' },
        { id: 'pricing', name: 'Pricing' },
        { id: 'contact', name: 'Contact' },
      ];

      for (const section of sections) {
        const element = document.getElementById(section.id);
        if (element) {
          const rect = element.getBoundingClientRect();
          if (rect.top <= scrollPosition && rect.bottom >= scrollPosition) {
            return section.name;
          }
        }
      }

      return null;
    },

    toggleMobileNav() {
      console.log('toggleMobileNav');
      
      this.isMobileNavOpen = !this.isMobileNavOpen;
    },

    handleNavClick() {
      if (this.isMobileNavOpen) {
        this.toggleMobileNav();
      }
    },

  },
};
</script>

<style scoped>
.fixed-top {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.mobile-nav-open ul {
  display: block;
}
</style>
