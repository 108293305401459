
<template>
  <section id="video" class="video-section py-3">
  
    <div class="text-center">
  
      <video width="100%" height="450" controls autoplay loop muted controlslist="nofullscreen">
        <source :src="require('@/assets/video/Mullayanov - Mullayanova, NED 2012 World Standard R1 Q.mp4')"
          type="video/mp4">
        Your browser does not support the video tag.
      </video>
  
    </div>
  
  </section>
  </template>
  
  
  <script></script>